import { ChatBot, NextPage, NextPageHead, PageView } from '@aspendental/shared-components-web';
import { PageThemeContextProvider } from '@aspendental/shared-components-web/lib/context/PageThemeProvider.ctx';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

import { getEntryById, updatePageContentsById } from './utils';
import getConfigByBrand from './utils/get-config-by-brand';
import getThemeByDomainName from './utils/get-theme-by-domain-name';
import { Ref, RefData, templatesWithRTFs } from './utils/rich-text-entry-links';

import { CACHE_CONTROL_NO_CACHE, CACHE_CONTROL_SHORT_TTL } from '@src/constants';
import {
	AppDetails,
	AppMetadataByAppNameDocument,
	AppPagesDataByAppNameDocument,
	Entry,
	FeatureFlagByAppDocument,
	LandingPageByAppNameWithPathDocument,
	PageContentsItem,
	PageFragment,
} from '@src/lib/__generated/sdk';
import { getClient } from '@src/lib/client';
import { getLogger } from '@src/lib/logging/logger';
import choosePreview from '@src/pages/utils/choose-preview';
import DetermineIndexing from '@src/pages/utils/determine-indexing';
import { getServerSideTranslations } from '@src/pages/utils/get-serverside-translations';

const Home = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
	const pageData = useContentfulLiveUpdates(props.page);
	const { url: logoUrl } = props.appData?.logo;
	const {
		path,
		title,
		indexed,
		description,
		canonical,
		breadcrumbsCollection,
		disableChatBot = false,
		analyticsContext,
	} = pageData;
	const pageTheme = props.page?.pageTheme || 'Primary';
	const { siteURL, features, featureFlags } = props.config as BrandThemeConfig;

	return (
		<>
			<PageThemeContextProvider pageTheme={pageTheme}>
				<PageView analyticsContext={analyticsContext} />
				<NextPageHead
					path={path}
					title={title}
					indexed={DetermineIndexing(indexed, props.env, props.preview)}
					description={description}
					canonical={canonical}
					breadcrumbs={breadcrumbsCollection?.items}
					originUrl={siteURL}
				/>
				<NextPage pageData={{ ...pageData }} />
				{!disableChatBot && (
					<ChatBot
						url={features.chat.chatBotURL}
						isIconButton={features.chat.isIconButton}
						logoUrl={logoUrl}
						featureList={featureFlags.featureList}
					/>
				)}
			</PageThemeContextProvider>
		</>
	);
};

export const getServerSideProps: GetServerSideProps = async ({ locale, req, res, preview = false }) => {
	const domain = req.headers.host;
	const theme = getThemeByDomainName(domain);
	const headerTagTheme = Array.isArray(req.headers['x-tag-theme'])
		? (req.headers['x-tag-theme'] as TagThemeType[])[0]
		: (req.headers['x-tag-theme'] as TagThemeType);
	// Priority: HTTP header x-tag-theme > domain > process.env.TAG_BRAND_THEME
	const tagTheme: TagThemeType = headerTagTheme || theme || process.env.TAG_BRAND_THEME || 'aspendental';
	const logger = getLogger('home', tagTheme);

	try {
		const brandConfig = getConfigByBrand(tagTheme);
		preview = choosePreview(req.headers.host, preview);

		const gqlClient = getClient(preview);
		if (gqlClient === null) {
			logger.error('GQL_CLIENT_IS_NULL: missing token for fetching contentful data');
			throw new Error('gqlClient is null');
		}
		const appName = brandConfig.contentfulAppId || process.env.TAG_APP_NAME || 'TAG_Commercial_Web_Sandbox';
		const [appPagesData, appMetadata] = await Promise.all([
			gqlClient.query(AppPagesDataByAppNameDocument, { appName, preview }).toPromise(),
			gqlClient.query(AppMetadataByAppNameDocument, { appName, preview }).toPromise(),
		]);

		if (appPagesData.error) {
			logger.error(`CONTENTFUL_ERROR_APP_PAGES_DATA: ${appPagesData.error}`);
		}

		if (appMetadata.error) {
			logger.error(`CONTENTFUL_ERROR_APP_META_DATA: ${appMetadata.error}`);
		}

		const appData = {
			...appPagesData.data?.appCollection?.items[0],
			...appMetadata.data?.appCollection?.items[0],
		};

		// replace [env] with environment name for locationBarFindOfficeUrl
		// TODO: create a brand variable context to replace those variables from CMS in future
		if (appData && appData.header?.locationBarFindOfficeUrl) {
			const brandSubdomain = process.env.ENVIRONMENT_NAME !== 'prod' ? 'wwwstg' : 'www';
			appData.header.locationBarFindOfficeUrl = appData.header?.locationBarFindOfficeUrl?.replace(
				'[env]',
				brandSubdomain
			);
		}
		if (!appData) {
			return {
				notFound: true,
			};
		}
		const pageData = await gqlClient
			.query(LandingPageByAppNameWithPathDocument, { appName, path: '/', preview })
			.toPromise();

		if (pageData.error) {
			logger.error(`CONTENTFUL_ERROR_HOME_PAGE: ${pageData.error}`);
		}

		const page = pageData.data.pageCollection?.items[0] as PageFragment;
		logger.info('/');
		if (!page) {
			logger.error(`COULD_NOT_FIND_THE_HOME_PAGE`);
			return {
				notFound: true,
			};
		}

		const getEntryLinks = async (item: PageContentsItem) => {
			if (!item?.__typename) return;

			const templateNames = Object.keys(templatesWithRTFs);
			const hasRichTextField = templateNames.includes(item.__typename);
			if (!hasRichTextField) return;

			const contentType = item.__typename;
			try {
				const templateLinks = gqlClient
					.query(templatesWithRTFs[contentType].query, { id: item?.sys?.id, preview })
					.toPromise();
				if (!templateLinks) {
					return undefined;
				}
				return templateLinks;
			} catch (e) {
				logger.error(`[${contentType}] ENTRY_LINKS_FETCH_FAILURE: ${e}`);
			}
		};

		const addEntryLinksToRichTextFields = (pageContents, templateLinks) => {
			return pageContents.map((item: PageContentsItem) => {
				if (!item || !item.__typename) return item;

				const { __typename: contentType } = item;
				const contentTypeId = contentType.charAt(0).toLowerCase() + contentType.slice(1);
				const { fields: richTextFields, refs: templateRefsWithRtf } = templatesWithRTFs[contentType] || {};

				const findRichTextLinksFragment = () =>
					templateLinks.find((links) => {
						return links?.data[contentTypeId]?.sys.id === item.sys.id;
					});

				const richTextLinksFragment = findRichTextLinksFragment();
				if (!richTextLinksFragment) return item;

				if (richTextFields) {
					richTextFields.forEach((field: string) => {
						const richTextLinks = richTextLinksFragment.data[contentTypeId][field]?.links;
						if (richTextLinks && item[field]) item[field].links = richTextLinks;
					});
				}

				const isCollection = (refName: string) => {
					return refName.includes('Collection');
				};

				const hasRefs = (refData: RefData): boolean => {
					return 'refs' in refData;
				};

				if (templateRefsWithRtf) {
					templateRefsWithRtf.forEach((ref) => {
						const linksData = isCollection(ref.name)
							? richTextLinksFragment.data[contentTypeId][ref.name].items
							: richTextLinksFragment.data[contentTypeId][ref.name];

						if (!linksData) return;

						const addLinksToNestedReferences = (refData: RefData, linksObject: Entry) => {
							const objectId = linksObject?.sys?.id;
							if (!objectId) return;
							refData.refs?.forEach((nestedRef: Ref) => {
								const nestedLinksData = isCollection(nestedRef.name)
									? linksObject[nestedRef.name]?.items
									: linksObject[nestedRef.name];
								nestedRef.data.forEach((nestedRefData) => {
									isCollection(nestedRef.name) &&
										nestedLinksData &&
										nestedLinksData.forEach((nestedLinkObject: Entry) => {
											const entryLinks = nestedLinkObject[nestedRefData.richTextField]?.links;
											const nestedItemId = nestedLinkObject?.sys?.id;
											if (!nestedItemId) return;

											const refItems = item[ref.name].items.find(
												(instance: Entry) => instance.sys.id === objectId
											);
											const itemToBeEnriched = refItems[nestedRef.name].items.find(
												(instance: Entry) => instance.sys.id === nestedItemId
											);
											if (entryLinks && itemToBeEnriched) {
												itemToBeEnriched[nestedRefData.richTextField].links = entryLinks;
											}
										});

									const entryLinks =
										nestedLinksData && nestedLinksData[nestedRefData.richTextField]?.links;
									if (!entryLinks) return;
									const itemToBeEnriched = isCollection(ref.name)
										? item[ref.name].items.find((instance: Entry) => instance.sys.id === objectId)
										: item[ref.name];

									if (entryLinks && itemToBeEnriched) {
										itemToBeEnriched[nestedRef.name][nestedRefData.richTextField].links =
											entryLinks;
									}
								});
							});
						};

						//Add rich text links to reference collection items
						isCollection(ref.name) &&
							linksData.forEach((linksObject: Entry) => {
								const objectId = linksObject?.sys?.id;
								ref.data.forEach((refData) => {
									const links = linksObject[refData.richTextField]?.links;
									const itemToBeEnriched = item[ref.name].items.find(
										(instance: Entry) => instance.sys.id === objectId
									);
									if (links && itemToBeEnriched) {
										itemToBeEnriched[refData.richTextField].links = links;
									}
									//Add rich text links to all references of a ref collection item
									if (hasRefs(refData)) {
										addLinksToNestedReferences(refData, linksObject);
									}
								});
							});

						//Add entry links to all rich text fields on a single reference
						ref.data.forEach((refData) => {
							const links = linksData[refData.richTextField]?.links;
							if (links) {
								item[ref.name][refData.richTextField].links = links;
							}
							//Add entry links to all references of a single reference
							if (hasRefs(refData)) {
								addLinksToNestedReferences(refData, linksData);
							}
						});
						return;
					});
				}

				return item;
			});
		};

		// fetch by id of content in contents
		const contentByIdPromises: Promise<any>[] = [];
		const initialPageContentsPromises = page?.contentsCollection?.items?.map(async (item) => {
			const contentByIdPromise = getEntryById(gqlClient, preview, logger, item as PageContentsItem);
			contentByIdPromises.push(contentByIdPromise);
			return item;
		});

		const initialPageContents = initialPageContentsPromises?.length
			? (await Promise.all(initialPageContentsPromises)).filter(Boolean)
			: [];
		const contentsFetchedById = (await Promise.all(contentByIdPromises)).filter(Boolean);

		// update contents data by id
		const enrichedContentsById = updatePageContentsById(
			initialPageContents as PageContentsItem[],
			contentsFetchedById
		);

		// Add links information for rich text rendering
		const entryLinksPromises: Promise<any>[] = [];
		const pageContentsPromises = enrichedContentsById.map((item) => {
			const entryLinksPromise = getEntryLinks(item as PageContentsItem);
			entryLinksPromises.push(entryLinksPromise);
			return item;
		});

		const pageContents = (await Promise.all(pageContentsPromises)).filter(Boolean);
		const entryLinks = (await Promise.all(entryLinksPromises)).filter(Boolean);

		// final contents to display on page
		const enrichedPageContents = addEntryLinksToRichTextFields(pageContents, entryLinks);

		// header nav menu feature flag
		const featureFlagData = await gqlClient
			.query(FeatureFlagByAppDocument, {
				appName: brandConfig.name,
				featureFlagName: 'HeaderNavigationMenu',
			})
			.toPromise();
		const appDetail = featureFlagData?.data?.featureFlagCollection?.items[0]?.appCollection?.items[0] as AppDetails;
		const showHeaderNavMenu = (appDetail && appDetail?.name) || false;

		// on page scheduling feature flag
		const featureFlagDataOnPageScheduling = await gqlClient
			.query(FeatureFlagByAppDocument, {
				appName: brandConfig.name,
				featureFlagName: 'OnPageScheduling',
			})
			.toPromise();

		const appDetailOnPageScheduling =
			(featureFlagDataOnPageScheduling?.data?.featureFlagCollection?.items[0]?.appCollection
				?.items as Array<AppDetails>) ?? [];

		const onPageSchedulingKey =
			featureFlagDataOnPageScheduling?.data?.featureFlagCollection?.items[0]?.name ?? 'onPageScheduling';
		const onPageSchedulingValue =
			featureFlagDataOnPageScheduling?.data?.featureFlagCollection?.items[0]?.value ?? false;

		const onPageSchedulingObj = {
			[onPageSchedulingKey]: onPageSchedulingValue,
		};

		if (preview) {
			res.setHeader('Cache-Control', CACHE_CONTROL_NO_CACHE);
		} else {
			res.setHeader('Cache-Control', CACHE_CONTROL_SHORT_TTL);
		}

		let hasIpBasedCoordinates = false;
		let coordinates = {
			latitude: 0,
			longitude: 0,
		};

		if (req.headers['cloudfront-viewer-latitude'] && req.headers['cloudfront-viewer-longitude']) {
			hasIpBasedCoordinates = true;
			coordinates = {
				latitude: parseFloat(req.headers['cloudfront-viewer-latitude'] as string),
				longitude: parseFloat(req.headers['cloudfront-viewer-longitude'] as string),
			};
			logger.info(`CDN_GEO_LOCATION_FOUND: [${coordinates?.latitude}, ${coordinates?.longitude}]`);
		} else {
			logger.info(`CDN_GEO_LOCATION_MISS`);
		}

		return {
			props: {
				...(await getServerSideTranslations(locale)),
				preview,
				env: {
					ENVIRONMENT_NAME: process.env.ENVIRONMENT_NAME,
				},
				config: {
					...brandConfig,
					...(!!appData.featureFlags ? { featureFlags: appData.featureFlags } : {}),
					schemaUrl: `${brandConfig.siteURL}${req.url}`,
				},
				page: {
					...(page || {}),
					contentsCollection: { items: enrichedPageContents },
				},
				appData,
				user: {
					coordinates,
					hasIpBasedCoordinates,
				},
				footerData: appData?.footer,
				showHeaderNavMenu,
				...(appDetailOnPageScheduling.length ? onPageSchedulingObj : {}),
			},
		};
	} catch (e) {
		logger.error(`HOME_PAGE_LOAD_FAILURE: ${e}`);
		return {
			notFound: true,
		};
	}
};

export default Home;
